<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(createSalesTarget)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <sales-targets-form
          :sales-target-data="salesTargetData"
          :status-options="statusOptions"
          :account-type-options="accountTypeOptions"
        />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useSalesTargetsForm from '../sales-targets-form/useSalesTargetsForm'
import SalesTargetsForm from '../sales-targets-form/SalesTargetsForm.vue'
import { statusOptions, accountTypeOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import useSystemCodes from '../../../system-settings/system-codes/useSystemCodes'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    SalesTargetsForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },

  setup() {
    const {
      salesTargetData,

      createSalesTarget,
    } = useSalesTargetsForm()

    const { callAccountType } = useSystemCodes()
    callAccountType()

    return {
      //  Data
      salesTargetData,

      //  Options
      statusOptions,
      accountTypeOptions,

      //  Action
      createSalesTarget,
    }
  },
}
</script>
